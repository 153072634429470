<template>
    <ModalAction
      :id="id"
      :okLabel="$i18n.t('btn_invitation_reject')"
      :cancelLabel="$i18n.t('btn_invitation_reject')"
      @handleOk="handleOk"
      isCentered
      onlyOk
    >
    <div class="clinic">
        <h4>{{$i18n.t('lb_collaboration_reject_title')}}</h4>
        <span>{{$i18n.t('lb_collaboration_reject_subtitle')}}</span>
        <p>{{invitation}}</p>
    </div>
    </ModalAction>
</template>
<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';

export default {
  name: 'InvitationRejectModal',
  props: {
    id: {
      type: String,
      required: true,
    },
    invitation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleOk() {
      this.$emit('handleRejectInvitation', this.invitation);
    },
  },
  components: {
    ModalAction,
  },
};
</script>
<style lang="sass" scoped>
  @import './invitation.sass'
</style>
